.team-control header {
    font-style: italic;
    font-size: 50px;
    font-weight: 900;
    color: black;
    margin-bottom: 20px;
    text-align: center;
    font-style: "normal";
}

.team-control {
    padding-bottom: 60px;
    padding-top: 60px;
    background-color: #242323;
}

.team-control header span {
    color: #000000;
    font-style: "normal"
}

.team-control-card{
    justify-content: center;
    text-align: center
}